<template>
  <b-card title="Listagem">
    <!-- table -->
    <listarDocumentosProcesso />

  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarDocumentosProcesso from '../../Listagens/DocumentosProcessos/ListarDocumentosProcesso.vue'

export default {
  components: {
    BCard,
    listarDocumentosProcesso,
  },

}

</script>
  <style lang="scss">
  </style>
