<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Pesquisar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'codigo'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.codigo }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'descricao'">

          {{ props.row.descricao }}

        </span>
        <span v-else-if="props.column.field === 'inactivo'">

          <b-form-checkbox
            v-if="props.row.inactivo === 1"
            v-model="props.row.inactivo"
            :value="1"
            :disabled="true"
          />

        </span>

        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$router.push({ name: 'editar-documentosProcesso-id', params: { id: props.row.id } })">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showMsgBoxTwo(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Apagar</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mostrar 1 até
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap "> de {{ props.total }} registos </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
// import axios from 'axios'

import {
  BFormGroup, BFormInput, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },

  data() {
    return {
      boxTwo: false,
      pageLength: 3,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      columns: [
        {
          label: 'Código',
          field: 'codigo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Descrição',
          field: 'descricao',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Inactivo',
          field: 'inactivo',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getDocumentosProcesso()
  },

  methods: {

    getDocumentosProcesso() {
      axios.get('/api/v1/documentosProcesso')
        .then(res => { this.rows = res.data })
      this.$forceUpdate()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    showMsgBoxTwo(row) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Pretende Remover ?', {
          title: 'Confirmação',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
          if (this.boxTwo === true) {
            this.deleted(row)
          }
        })
    },

    deleted(row) {
      axios.delete(`/api/v1/documentosProcesso/${row.id}`)
        .then(
          this.rows = this.rows.filter(profile => profile.id !== row.id),
        )
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
